"use client";

import { allQueryKeys } from "#app/_api/allQueryKeys";
import { useUserEligibility } from "#app/_api/fund-transfer-service-user";
import { AccountInfo } from "#app/_ui/components/accounts/AccountInfo";
import { ErrorCardContent } from "#app/_ui/components/cards/ErrorCard";
import { PendingCardContent } from "#app/_ui/components/cards/PendingCard";
import { formatCurrency } from "#app/_ui/components/textFormaters/Money";
import { logError } from "#app/lib/logger";
import { Button } from "#ui/button";
import { type CardRootProps, Card, CardContent, CardHeader } from "#ui/card";
import {
  CardTabs,
  CardTabsContent,
  CardTabsList,
  CardTabsTrigger,
} from "#ui/card-tabs";
import { GainLoss } from "#ui/gain-loss";
import { Icon } from "#ui/icon";
import { LinkButton } from "#ui/link";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from "#ui/popover";
import { cx } from "#ui/style.utils";
import { H2, P, Span } from "#ui/typography";
import { useQuery } from "@tanstack/react-query";
import Image from "next/image";
import { Children, useState } from "react";

export function CardMyPortfolio(props: CardRootProps) {
  return (
    <CardTabs defaultValue="accounts">
      <Card {...props}>
        <CardHeader>
          <H2 size="h6" className="flex items-center gap-10px">
            <Icon name="baird-pie-chart" size="baird-md" />
            <span>My Portfolio</span>
          </H2>
        </CardHeader>
        <CardTabsList placement="top-outside" className="justify-center">
          <CardTabsTrigger value="accounts">Accounts</CardTabsTrigger>
          <CardTabsTrigger value="account-groups">
            Account Groups
          </CardTabsTrigger>
        </CardTabsList>
        <CardContent>
          <CardTabsContent value="accounts" className="-mt-30px space-y-20px">
            <AccountsTab />
          </CardTabsContent>
          <CardTabsContent
            value="account-groups"
            className="-mt-30px space-y-20px"
          >
            <AccountGroupsTab />
          </CardTabsContent>
        </CardContent>
      </Card>
    </CardTabs>
  );
}

function AccountsTab() {
  const { data, isPending, isError, error } = useQuery(
    allQueryKeys.pwmPortfolioService.portfolio(),
  );

  if (isPending) {
    return <PendingCardContent />;
  }

  if (isError || data === undefined) {
    logError(error);
    return <ErrorCardContent />;
  }

  return (
    <MyPortfolioList label="account">
      {data.accounts
        .sort((x, y) => (x.value > y.value ? -1 : 1))
        .map((el) => (
          <MyPortfolioListItem
            key={el.number}
            identifier={el.number}
            itemName={el.nickname}
            itemValue={el.value}
            percentChange={el.percentChange}
          />
        ))}
    </MyPortfolioList>
  );
}

function AccountGroupsTab() {
  const { data, isPending, isError, error, isSuccess } = useQuery(
    allQueryKeys.pwmPortfolioService.accountGroupsPortfolios,
  );

  if (isPending) {
    return <PendingCardContent />;
  }

  if (isError || data === undefined) {
    logError(error);
    return <ErrorCardContent />;
  }

  if ((isSuccess && data === undefined) || data.portfolios.length === 0) {
    return (
      <div className="space-y-30px pt-30px text-center">
        <Image
          alt=""
          src="/Images/image-empty-state.png"
          width="420"
          height="321"
          className="mx-auto w-148px sm:w-211px"
          priority
        />
        <P size="h6">{"You haven't created any account groups."}</P>
        <LinkButton
          className="text-sm max-sm:hidden"
          variant="solid"
          palette="accent"
          href="/profile-and-settings/account-groups"
        >
          Create a group
          <Icon name="ms-navigate-next" />
        </LinkButton>
      </div>
    );
  }

  const accountGroup = data.accountGroups
    .filter((el) => el.acctIndexes.length > 0)
    .map((acctGroup, idx) => ({
      groupId: acctGroup.id,
      groupName: acctGroup.name,
      numberOfAccounts: acctGroup.acctIndexes.length,
      marketValue: data.portfolios[idx]!.totals.value,
      percentChange: data.portfolios[idx]!.totals.percentChange,
    }))
    .sort((x, y) => (x.marketValue > y.marketValue ? -1 : 1));

  return (
    <MyPortfolioList label="group">
      {accountGroup.map((el) => (
        <MyPortfolioListItem
          key={el.groupId}
          itemName={el.groupName}
          itemValue={el.marketValue}
          percentChange={el.percentChange}
          identifier={el.groupId}
          numberofAccounts={el.numberOfAccounts}
          isGroup
        />
      ))}
    </MyPortfolioList>
  );
}

function MyPortfolioList({
  children,
  label,
}: {
  children: React.ReactNode;
  label: string;
}) {
  const listItems = Children.toArray(children);
  const [shortList, setShortList] = useState(true);
  const derivedlist = shortList ? listItems.slice(0, 3) : listItems;
  const hiddenLabel =
    listItems.length === 4
      ? `Show 1 more ${label}`
      : `Show ${listItems.length - 3} more ${label}s`;
  const shownLabel = `Hide ${label}s`;

  return (
    <ul className="space-y-20px">
      {derivedlist}
      {listItems.length >= 4 ? (
        <Button
          variant="outline"
          palette="accent"
          className="mx-auto flex max-sm:w-full"
          onClick={() => setShortList(!shortList)}
        >
          {shortList ? hiddenLabel : shownLabel}
        </Button>
      ) : null}
    </ul>
  );
}

function MyPortfolioListItem({
  itemName,
  itemValue,
  percentChange,
  identifier,
  numberofAccounts,
  isGroup,
}: {
  itemName: string;
  itemValue: number;
  percentChange: number;
  identifier: string;
  numberofAccounts?: number;
  isGroup?: boolean;
}) {
  return (
    <li className="rounded-lg border border-shade-15">
      <div className="flex flex-col gap-y-30px p-30px max-sm:py-30px md:flex-row md:justify-between">
        <div className="flex flex-row gap-15px">
          <Icon
            className="h-40px w-40px rounded-md bg-primary-100 p-10px"
            name={isGroup ? "baird-logo-layered" : "baird-logo"}
            palette="white"
            size="baird-md"
          />
          <div className="flex flex-col gap-y-6px">
            <div>
              {isGroup ? (
                <>
                  <div className="text-sm">{itemName}</div>
                  <div className="text-sm text-shade-70">
                    {numberofAccounts}&nbsp;
                    {numberofAccounts === 1 ? "Account" : "Accounts"}
                  </div>
                </>
              ) : (
                <AccountInfo
                  accountNumber={identifier}
                  accountNickname={itemName}
                  // accountType={data.accountType}
                  variant="triple"
                  hasInfoPopover
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex w-fit flex-col gap-y-15px md:items-end">
          <div>
            <Span className="text-h3 md:text-right">
              {formatCurrency(itemValue)}
            </Span>
          </div>
          <div className="flex items-center gap-5px">
            <GainLoss value={percentChange} format="percent" />
            <Span palette="neutral-subtle" size="sm">
              Daily Change
            </Span>
          </div>
        </div>
      </div>

      <MyPortfolioButtons
        identifier={identifier}
        isGroup={isGroup ? isGroup : false}
      />
    </li>
  );
}

function MyPortfolioButtons({
  identifier,
  isGroup,
}: {
  identifier: string;
  isGroup: boolean;
}) {
  const { data, isSuccess } = useQuery(useUserEligibility());

  const baseUrl = `&ACXRelayHelper_Input_App.`;
  const acctQuery = `Account=${identifier}`;
  const acctGroupQuery = `AccountGroupSecure=${identifier}`;
  const filterEncoded = btoa(
    isGroup ? baseUrl + acctGroupQuery : baseUrl + acctQuery,
  );

  const isHide =
    isSuccess && data.ach === "Hide" && data.onlineTransferRequest === "Hide";

  return (
    <div className="flex flex-row gap-10px rounded-b-lg border-t border-t-shade-15 bg-shade-2 px-30px py-15px">
      <LinkButton
        href="/account-services/transfers/transfer-form"
        variant="outline"
        palette="neutral"
        className={cx(isHide ? "hidden" : "max-[500px]:w-full")}
      >
        Transfer funds
      </LinkButton>
      <LinkButton
        href={{
          pathname: "/investments/holdings",
          query: { filterEncoded },
        }}
        variant="outline"
        palette="neutral"
        className={cx(isHide ? "max-[500px]:w-full" : "max-[500px]:hidden")}
      >
        View investments
      </LinkButton>
      <LinkButton
        href={{
          pathname: "/investments/activity",
          query: { filterEncoded },
        }}
        variant="outline"
        palette="neutral"
        className={cx(isHide ? "max-[500px]:hidden" : "max-[630px]:hidden")}
      >
        View activity
      </LinkButton>
      <LinkButton
        href={{
          pathname: "/documents",
          query: { filterEncoded: btoa(identifier) },
        }}
        variant="outline"
        palette="neutral"
        className={cx(isHide ? "max-[645px]:hidden" : "max-[805px]:hidden")}
      >
        View documents
      </LinkButton>
      <LinkButton
        href={{
          pathname: "/profile-and-settings/account-groups",
          query: { filterEncoded },
        }}
        variant="outline"
        palette="neutral"
        className={cx(
          isHide ? "max-[860px]:hidden" : "max-[995px]:hidden xl:hidden",
        )}
      >
        {isGroup ? "Edit account group" : "Edit account nickname"}
      </LinkButton>
      <LinkButton
        href={{
          pathname: "/investments/accounts",
          query: { filterEncoded },
        }}
        variant="outline"
        palette="neutral"
        className={cx(
          isHide ? "max-[1070px]:hidden" : "max-[1210px]:hidden xl:hidden",
        )}
      >
        Show account information
      </LinkButton>

      <ToggleMoreActions>
        <LinkButton
          href={{
            pathname: "/investments/holdings",
            query: { filterEncoded },
          }}
          variant="outline"
          palette="neutral"
          className={cx(isHide ? "hidden" : "min-[500px]:hidden")}
        >
          View investments
        </LinkButton>
        <LinkButton
          href={{
            pathname: "/investments/activity",
            query: { filterEncoded },
          }}
          variant="outline"
          palette="neutral"
          className={cx(isHide ? "min-[500px]:hidden" : "min-[630px]:hidden")}
        >
          View activity
        </LinkButton>
        <LinkButton
          href={{
            pathname: "/documents",
            query: { filterEncoded: btoa(identifier) },
          }}
          variant="outline"
          palette="neutral"
          className={cx(isHide ? "min-[645px]:hidden" : "min-[805px]:hidden")}
        >
          View documents
        </LinkButton>
        <LinkButton
          href={{
            pathname: "/profile-and-settings/account-groups",
            query: { filterEncoded },
          }}
          variant="outline"
          palette="neutral"
          className={cx(
            isHide ? "min-[860px]:hidden" : "min-[995px]:hidden xl:inline-flex",
          )}
        >
          {isGroup ? "Edit account group" : "Edit account nickname"}
        </LinkButton>
        <LinkButton
          href={{
            pathname: "/investments/accounts",
            query: { filterEncoded },
          }}
          variant="outline"
          palette="neutral"
          className={cx(
            isHide
              ? "min-[1070px]:hidden"
              : "min-[1210px]:hidden xl:inline-flex",
          )}
        >
          Show account information
        </LinkButton>
        <LinkButton
          href={{
            pathname: "/investments/performance",
            query: { filterEncoded: btoa(identifier) },
          }}
          variant="outline"
          palette="neutral"
        >
          View performance
        </LinkButton>
      </ToggleMoreActions>
    </div>
  );
}

function ToggleMoreActions({ children }: { children: React.ReactNode }) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          iconOnly
          variant="outline"
          palette="neutral"
          className="shrink-0"
        >
          <Icon name="ms-more-vert" />
          <span className="sr-only">More actions</span>
        </Button>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent palette="white" size="sm" side="top" className="py-5px">
          <div className="flex flex-col gap-y-5px">{children}</div>
          <PopoverArrow palette="white" />
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
}
