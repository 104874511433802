"use client";

import {
  useMutationDeleteNotification,
  type GetNotificationsResponse,
} from "#app/_api/notification-service";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogCloseIconButton,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogMain,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  type AlertDialogTriggerProps,
} from "#ui/alert-dialog";
import { Button } from "#ui/button";
import { Icon } from "#ui/icon";
import { useState } from "react";

export function MyUpdatesDialogDelete({
  children,
  data,
}: {
  children: AlertDialogTriggerProps["children"];
  data: Required<
    Pick<Awaited<GetNotificationsResponse>[number], "typeId" | "updateId">
  >;
}) {
  const [open, setOpen] = useState(false);
  const mutationDeleteNotification = useMutationDeleteNotification();

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent
          variant="center-scroll-inner"
          className="max-w-450px gap-30px p-30px pt-50px"
        >
          <AlertDialogMain className="p-0">
            <div className="flex flex-col items-center space-y-14px text-center">
              <Icon name="baird-close-circle" size="baird-lg" palette="error" />
              <AlertDialogTitle className="font-semibold">
                Are you sure you want to delete this update?
              </AlertDialogTitle>
              <AlertDialogDescription className="text-sm font-normal text-shade-70">
                This cannot be undone.
              </AlertDialogDescription>
            </div>
            <AlertDialogCancel asChild>
              <AlertDialogCloseIconButton />
            </AlertDialogCancel>
          </AlertDialogMain>
          <AlertDialogFooter className="m-0 border-t-0 p-0">
            <Button
              onClick={() =>
                mutationDeleteNotification.mutate(
                  { typeId: data.typeId, updateId: data.updateId },
                  { onSettled: () => setOpen(false) },
                )
              }
              pending={mutationDeleteNotification.isPending}
              palette="error"
              className="order-last"
            >
              Delete
            </Button>
            <AlertDialogCancel asChild>
              <Button variant="outline">Cancel</Button>
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}
