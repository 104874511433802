import { getCurrentJwt } from "#app/(unauthorized)/authentication/jwt";
import { getBlob, getJson, postBlob } from "#app/lib/fetchClient";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { DocumentMetaData, DocumentsEndpointParams, generateDocumentsQuery } from "./all-documents-api";

export type StatementData = {
  documentId: number;
  documentDate: Date;
  pdfDate: string; // DO NOT USE
  repCode: string;
  accountNumber?: number;
  marketValue?: number;
  parentAccount?: number;
  isMasterConnect: boolean;
  statementRange: string;
  isCais: boolean;
  name: string;
  statementBackerUrl: string;
};

const GATEWAY_PATH = "Statements/v1";

export async function getStatements(
  accounts: string[],
  month?: string,
  year?: string,
): Promise<StatementData[]> {
  const jwt = getCurrentJwt();
  const accountIndices = jwt.getAccountIndex(accounts);
  const accountNumberIndex = accountIndices
    .map((a) => encodeURIComponent(a))
    .join(",");
    
  const monthString = !!month && month !== "all" ? `&month=${encodeURIComponent(month)}` : "";
  const yearString = !!year && year !== "all" ? `&year=${encodeURIComponent(year)}` : "";

  return await getJson<StatementData[]>(
    `/${GATEWAY_PATH}/Statements?accountNumberIndex=${accountNumberIndex}${monthString}${yearString}`,
  );
}

export async function getStatementsDocuments(
  {accounts, beginDate, endDate }: DocumentsEndpointParams
): Promise<DocumentMetaData[]> {
  const paramsString = generateDocumentsQuery({accounts, beginDate, endDate});

  return await getJson<DocumentMetaData[]>(
    `/${GATEWAY_PATH}/Statements/Documents?${paramsString}`,
  );
}

export async function getStatementsSinglePdf(
  documentId: number,
): Promise<Blob> {
  return await getBlob(
    `/${GATEWAY_PATH}/Statements/pdf?documentId=${encodeURIComponent(documentId)}`,
  );
}

export async function getStatementsMultiplePdf(
  documentIds: number[],
): Promise<Blob> {
  return await postBlob(`/${GATEWAY_PATH}/Statements/Multiple/pdf`, {
    json: { documentIds },
  });
}

export type LatestAvailable = {
  monthStr: string,
  yearStr: string
}

export async function getLatestAvailableDate(): Promise<LatestAvailable> {
  const latest = await getJson<string>(
    `/${GATEWAY_PATH}/Statements/LatestAvailableDate`,
  );
  const monthStr = format(new Date(latest), "MM");
  const yearStr = format(new Date(latest), "yyyy");
  return { monthStr: monthStr, yearStr: yearStr };
}

export type StatementFilter = {
  accounts: string[];
  month?: string;
  year?: string;
}

export const statementsQueryKeys = createQueryKeys("Statements", {
  list: ({accounts, month, year }: StatementFilter) => ({
    queryKey: [{ accounts, month, year }],
    queryFn: () => getStatements(accounts, month, year),
  }),
  singlePdf: (documentId: number) => ({
    queryKey: [documentId],
    queryFn: () => getStatementsSinglePdf(documentId),
  }),
  multiplePdf: (documentIds: number[]) => ({
    queryKey: [{ documentIds }],
    queryFn: () => getStatementsMultiplePdf(documentIds),
  }),
  latestAvailableDate: {
    queryKey: null,
    queryFn: () => getLatestAvailableDate(),
  },
  documents: ({accounts, beginDate, endDate }: DocumentsEndpointParams) => ({
    queryKey: [{accounts, beginDate, endDate }],
    queryFn: () => getStatementsDocuments({accounts, beginDate, endDate })
  })
});

export function useStatementsQuery({accounts, month, year}: StatementFilter)
{

  return useQuery({
    ...statementsQueryKeys.list({
      accounts,
      month,
      year,
    }),
    placeholderData: keepPreviousData,
    enabled: !!accounts,
  });
}

export function useStatementsDocumentsQuery({accounts, beginDate, endDate }: DocumentsEndpointParams)
{
  return useQuery({
    ...statementsQueryKeys.documents(
      {accounts, beginDate, endDate }),
      placeholderData: keepPreviousData,
      enabled: !!accounts,
  });
}

export function useStatementsSinglePdfQuery(documentId: number) {
  return useQuery(statementsQueryKeys.singlePdf(documentId));
}

export function useStatementsMultiplePdfQuery(documentIds: number[]) {
  return useQuery(statementsQueryKeys.multiplePdf(documentIds));
}



export function useLatestAvailableDate() {
  return useQuery(statementsQueryKeys.latestAvailableDate);
}
